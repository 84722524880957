import React from 'react'
import { graphql } from 'gatsby'
import * as ds from "../uiLibrary"
import Layout from '../components/layouts'
import { MediaKit, FactSheet } from '../components'
import PressRollPreview from '../components/PressRollPreview'
import MediaRollPreview from '../components/MediaRollPreview'
import { InnerPageHero } from '../components/heroes'
import HeadMeta from '../components/HeadMeta'

const MediaPage = ({ data }) => {
  const page = data.allMultimediaJson.edges[0].node
  return (
    <Layout>
      <HeadMeta
        pageTitle={page.pageTitle || 'Media - Tonit'}
        pageDescription={page.pageDescription}
        socialTitle={page.socialTitle}
        socialDescription={page.socialDescription}
        socialImage={page.socialImage}
      />
      <InnerPageHero url={page.heroBackground} alt="Tonit Motorcycle FAQ">
        <ds.FixedWidthRow>
          <ds.Column span={8}>
            <ds.Headline fontSize={[ds.typeScale.t6, ds.typeScale.t8]}>
              {page.heroHeading}
            </ds.Headline>
            <ds.H2>
              {page.heroBody}
            </ds.H2>
          </ds.Column>
        </ds.FixedWidthRow>
      </InnerPageHero>
      <ds.Background bg={ds.colors.bg.lightGray}>
        <ds.FixedWidthRow py={3} display={["none", "grid", "grid"]}>
          {page.buttons.map((button) => (
            <ds.Column key={button.text} span={3}>
              <ds.A color="black" href={button.url} >
                <ds.ClickableCard width="50%" textAlign="center" py={ds.spacing.s3}>
                  {button.text}
                </ds.ClickableCard>
              </ds.A>
            </ds.Column>
          ))}
        </ds.FixedWidthRow>
      </ds.Background>
      <ds.Background bg={ds.colors.white}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <ds.H2 id="media" color={ds.colors.black} pb={3}>{page.mediaHeading}</ds.H2>
            <MediaRollPreview />
            <ds.StyledLink to={page.mediaUrl}><ds.P color={ds.colors.brandRed}>{page.mediaText}</ds.P></ds.StyledLink>
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
      <ds.Background bg={ds.colors.bg.lightGray}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <ds.H2 id="press" color={ds.colors.black} pb={3}>{page.pressHeading}</ds.H2>
            <PressRollPreview />
            <ds.StyledLink to={page.pressUrl}><ds.P color={ds.colors.brandRed}>{page.pressText}</ds.P></ds.StyledLink>
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
      <ds.Background bg={ds.colors.white}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <ds.H2 id="mediakit" color={ds.colors.black} pb={3}>{page.mediaKitHeading}</ds.H2>
            <MediaKit videos={page.videos} />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
      <ds.Background bg={ds.colors.bg.lightGray}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <ds.H2 id="factsheet" color={ds.colors.black} pb={3}>{page.factsHeading}</ds.H2>
            <FactSheet facts={page.facts} />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMultimediaJson {
      edges {
        node {
          heroHeading
          heroBody
          heroBackground
          buttons {
            text
            url
          }
          mediaHeading
          mediaText
          mediaUrl
          pressHeading
          pressText
          pressUrl
          mediaKitHeading
          videos {
            title
            url
          }
          factsHeading
          facts {
            title
            details
          }
          pageTitle
          pageDescription
          socialTitle
          socialDescription
          socialImage
        }
      }
    }
  }
`

export default MediaPage
